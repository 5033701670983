<template>
  <b-sidebar id="sidebar-addPassenger" :visible="value" @change="val => $emit('input', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex align-items-center content-sidebar-header w-100">
        <b-button v-if="$can('TRIP_VIEW')" variant="flat-primary" class="mr-50" size="sm" :to="{ name: 'OrdersManager' }">
          <font-awesome-icon class="mr-50" icon="receipt" />
          {{ $t('trip.action.show_orders') }}
        </b-button>
        <!-- <b-button variant="flat-primary" size="sm">
          <font-awesome-icon icon="file-pdf" class="mr-50" />
          {{ $t('action.export') }}
        </b-button> -->

        <feather-icon class="mr-1 ml-auto cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- RESUME -->
      <div v-if="!$store.state.auth.selectedOrganization.seller">
        <b-card-title v-if="roundTrip.extendedProps.trip.name" class="h1 text-center mx-2 mt-2">
          {{ roundTrip.extendedProps.trip.name }}
        </b-card-title>
        <b-card-sub-title v-if="roundTrip.extendedProps.trip.creator && roundTrip.extendedProps.trip.creator.name" class="text-center">
          <span class="font-small-3">{{ $t('common.created_by') }} </span>
          <span>{{ roundTrip.extendedProps.trip.creator.name }}</span>
        </b-card-sub-title>
      </div>
      <div v-else>
        <b-card-title v-if="roundTrip.extendedProps.trip.organization" class="h1 text-center mx-2 mt-2">
          {{ roundTrip.extendedProps.trip.organization.legalName }}
        </b-card-title>
      </div>

      <div class="mt-3 mb-1 mx-2">
        <section class="mb-1">
          <div v-if="roundTrip.extendedProps.step.className === 'TripFlight' && roundTrip.extendedProps.trip.organizationAircraft">
            <span>
              <font-awesome-icon icon="plane" class="mr-50" />
              {{ roundTrip.extendedProps.trip.organizationAircraft.type }} - {{ roundTrip.extendedProps.trip.organizationAircraft.callSign }}<span v-if="roundTrip.extendedProps.trip.organizationAircraft.registration"> / {{ roundTrip.extendedProps.trip.organizationAircraft.registration }}</span>
            </span>
          </div>
          <!--SUBTEXT AIRCRAFT-->
          <div v-else>
            <font-awesome-icon icon="helicopter" class="mr-50" />
            <span>{{ $t('trip.type.helicopter_only') }}</span>
          </div>
        </section>

        <!--DEPARTURE TO / ARRIVAL FROM pour vol (seller only) -->
        <b-overlay :show="isLoading">
          <section v-if="$store.state.auth.selectedOrganization.seller && !isLoading && tripStepEvent && tripStepEvent.className && tripStepEvent.className.includes('Flight')" class="mb-1">
            <div v-if="displayDeparture">
              <div class="font-weight-bold">{{ $t('trip.departure_to') }} :</div>
              <font-awesome-icon icon="plane-arrival" class="mx-50" />
              {{ tripStepEventName(roundTrip.extendedProps.arrival) }}

              <!-- Datetime UTC -->
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span class="font-weight-bold">{{ $t('trip.event.etd') }} : </span>

                <span>
                  {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatUtc }}
                  {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
                </span>
              </div>

              <!-- Datetime LocalTime -->
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span class="font-weight-bold">{{ $t('trip.event.etd') }} : </span>

                <span>
                  {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }}
                  {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }} {{ $t('common.local_time') }}
                </span>
              </div>
            </div>

            <div v-if="displayArrival" class="mt-2">
              <div class="font-weight-bold">{{ $t('trip.arrival_from') }} :</div>
              <font-awesome-icon icon="plane-departure" class="mx-50" />
              {{ tripStepEventName(roundTrip.extendedProps.departure) }}

              <!-- Datetime UTC -->
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span class="font-weight-bold">{{ $t('trip.event.eta') }} : </span>

                <span>
                  {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatUtc }}
                  {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
                </span>
              </div>

              <!-- Datetime LocalTime -->
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span class="font-weight-bold">{{ $t('trip.event.eta') }} : </span>

                <span>
                  {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }}
                  {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }} {{ $t('common.local_time') }}
                </span>
              </div>
            </div>
          </section>
        </b-overlay>

        <!--DEPARTURE TO / ARRIVAL FROM pour vol (buyer only) -->
        <section v-if="$store.state.auth.selectedOrganization.buyer && roundTrip.extendedProps.departure.className.includes('Flight')" class="mb-1">
          <!-- DEPARTURE (buyer) -->
          <section class="mb-1">
            <div>
              <div class="font-weight-bold">{{ $t('trip.departure') }} :</div>
              <font-awesome-icon icon="plane" class="mx-50" />
              {{ tripStepEventName(roundTrip.extendedProps.departure) }}
            </div>

            <div class="d-flex align-items-center">
              <font-awesome-icon icon="clock" class="mx-50" size="1x" />
              <span class="font-weight-bold">{{ $t('trip.event.etd') }}</span>
              <span class="mx-50"> : </span>
              <span>{{ roundTrip.extendedProps.departure.eventDateTime | dateFormatUtc }}</span>
              <span class="mx-50"> - </span>
              <span v-if="roundTrip.extendedProps.departure.eventDateTime">
              {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
            </span>
            </div>
          </section>

          <!-- ARRIVAL (buyer) -->
          <section class="mb-1">
            <div>
              <div class="font-weight-bold">{{ $t('trip.arrival') }} :</div>
              <font-awesome-icon icon="plane" class="mx-50" />
              {{ tripStepEventName(roundTrip.extendedProps.arrival) }}
            </div>
            <div v-if="roundTrip.extendedProps.departure.endsAt" class="d-flex align-items-center">
              <font-awesome-icon icon="clock" class="mx-50" size="1x" />
              <span class="font-weight-bold">{{ $t('trip.event.eta') }} : </span>
              <span>{{ roundTrip.extendedProps.arrival.endsAt | dateFormatUtc }}</span>
              <span class="mx-50"> - </span>
              <span>
              {{ roundTrip.extendedProps.arrival.endsAt | timeFormatUtc }} {{ $t('common.utc') }}
            </span>
            </div>
          </section>
        </section>

        <!--DEPARTURE TO / ARRIVAL FROM pour transfer (seller only) -->
        <section v-if="$store.state.auth.selectedOrganization.seller && !isLoading && tripStepEvent && tripStepEvent.className && tripStepEvent.className.includes('Transfer')" class="mb-1">
          <b-overlay :show="isLoading">
            <div v-if="!isLoading && tripStepEvent && tripStepEvent.className">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold">{{ $t('trip.departure_from') }} :</div>
                <font-awesome-icon icon="helicopter" class="mx-50" />
                <span>
                  {{ tripTransferEventName(roundTrip.extendedProps.departure, roundTrip.extendedProps.step.departureDepositZone) }}
                </span>
              </div>

              <div class="d-flex align-items-center">
                <div class="font-weight-bold">{{ $t('trip.arrival_to') }} :</div>
                <font-awesome-icon icon="helicopter" class="mx-50" />
                <span>
                  {{ tripTransferEventName(roundTrip.extendedProps.arrival, roundTrip.extendedProps.step.arrivalDepositZone) }}
                </span>
              </div>

              <!-- Datetime ETD -->
              <h5 class="mt-1">{{ $t('trip.event.etd') }}</h5>
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span>
                  {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatUtc }}
                  {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
                </span>
              </div>

              <div class="d-flex align-items-center">
                <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                <span>
                  {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }}
                  {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }} {{ $t('common.local_time') }}
                </span>
              </div>

              <!-- Datetime ETA -->
              <section v-if="roundTrip.extendedProps.arrival.eventDateTime">
                <h5 class="mt-1">{{ $t('trip.event.eta') }}</h5>
                <div class="d-flex align-items-center">
                  <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                  <span>
                    {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatUtc }}
                    {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <font-awesome-icon icon="clock" class="mx-50" size="1x" />
                  <span>
                    {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.arrival)) }}
                    {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.arrival)) }} {{ $t('common.local_time') }}
                  </span>
                </div>
              </section>
            </div>
          </b-overlay>
        </section>

        <!--DEPARTURE TO / ARRIVAL FROM pour transfer (buyer only) -->
        <section v-if="$store.state.auth.selectedOrganization.buyer && roundTrip.extendedProps.departure.className.includes('Transfer')" class="mb-1">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">{{ $t('trip.departure_from') }} :</div>
            <font-awesome-icon icon="helicopter" class="mx-50" />
            <span>
              {{ tripTransferEventName(roundTrip.extendedProps.departure, roundTrip.extendedProps.step.departureDepositZone) }}
            </span>
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold">{{ $t('trip.arrival_to') }} :</div>
            <font-awesome-icon icon="helicopter" class="mx-50" />
            <span>
              {{ tripTransferEventName(roundTrip.extendedProps.arrival, roundTrip.extendedProps.step.arrivalDepositZone) }}
            </span>
          </div>

          <!-- Datetime ETD -->
          <h5 class="mt-1">{{ $t('trip.event.etd') }}</h5>
          <div class="d-flex align-items-center">
            <font-awesome-icon icon="clock" class="mx-50" size="1x" />
            <span>
              {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatUtc }}
              {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
            </span>
          </div>

          <div class="d-flex align-items-center">
            <font-awesome-icon icon="clock" class="mx-50" size="1x" />
            <span>
              {{ roundTrip.extendedProps.departure.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }}
              {{ roundTrip.extendedProps.departure.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.departure)) }} {{ $t('common.local_time') }}
            </span>
          </div>

          <!-- Datetime ETA -->
          <section v-if="roundTrip.extendedProps.arrival.eventDateTime">
            <h5 class="mt-1">{{ $t('trip.event.eta') }}</h5>
            <div class="d-flex align-items-center">
              <font-awesome-icon icon="clock" class="mx-50" size="1x" />
              <span>
                {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatUtc }}
                {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatUtc }} {{ $t('common.utc') }}
              </span>
            </div>
            <div class="d-flex align-items-center">
              <font-awesome-icon icon="clock" class="mx-50" size="1x" />
              <span>
                {{ roundTrip.extendedProps.arrival.eventDateTime | dateFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.arrival)) }}
                {{ roundTrip.extendedProps.arrival.eventDateTime | timeFormatLocal(getEventTimezone(roundTrip.extendedProps.step, roundTrip.extendedProps.arrival)) }} {{ $t('common.local_time') }}
              </span>
            </div>
          </section>
        </section>

        <!--TYPE FLIGHT-->
        <section v-if="roundTrip.extendedProps.step.className === 'TripFlight' && roundTrip.extendedProps.trip.flightMission" class="mb-1">
          <div>
            <span class="font-weight-bold">{{ $t('trip.event.flight_type') }} : </span>
            <span>{{ roundTrip.extendedProps.step.flightType | enumTranslate('flight_type') }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.event.flight_mission') }} : </span>
            <span>{{ roundTrip.extendedProps.step.flightMission | enumTranslate('flight_mission') }}</span>
          </div>
        </section>

        <!--FLIGHT STATUS-->
        <section v-if="roundTrip.extendedProps.step.className === 'TripFlight'" class="mb-1">
          <div>
            <span class="font-weight-bold">{{ $t('trip.flight_plan.number') }} : </span>
            <span>{{ roundTrip.extendedProps.step.flightPlanNumber }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.flight_plan.status') }} : </span>
            <!--                En attente d'avoir les flight plan status-->
          </div>
        </section>

        <section class="mb-1">
          <!--FLIGHT PASSENGERS-->
          <div v-if="roundTrip.extendedProps.trip.crew">
            <span class="font-weight-bold">{{ $tc('trip.event.crew_number', roundTrip.extendedProps.trip.crew) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.crew }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.trip.passengers">
            <span class="font-weight-bold">{{ $tc('trip.event.passenger_number', roundTrip.extendedProps.trip.passengers) }} : </span>
            <span>{{ roundTrip.extendedProps.trip.passengers }}</span>
          </div>
          <div v-if="roundTrip.extendedProps.step.className === 'TripTransfer'">
            <div v-if="roundTrip.extendedProps.step.luggage">
              <span class="font-weight-bold">{{ $tc('luggage.luggage', roundTrip.extendedProps.step.luggage) }} : </span>
              <span>{{ roundTrip.extendedProps.step.luggage }}</span>
            </div>
            <div v-if="roundTrip.extendedProps.step.bags">
              <span class="font-weight-bold">{{ $tc('luggage.bags', roundTrip.extendedProps.step.bags) }} : </span>
              <span>{{ roundTrip.extendedProps.step.bags }}</span>
            </div>
          </div>
        </section>

        <!-- Estimated cost (only if it available, so different of null) -->
        <section v-if="roundTrip.extendedProps.step.stepTotalOrdersBeforeTaxes !== 0" class="mb-1">
          <div class="h4">
            <span>{{ $t('trip.total_legs.estimated_cost') }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.total_legs.total_orders_before_tax') }} : </span>
            <span>{{ roundTrip.extendedProps.step.stepTotalOrdersBeforeTaxes | priceFormat }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('trip.total_legs.total_orders_including_tax') }} : </span>
            <span>{{ roundTrip.extendedProps.step.stepTotalOrdersIncludingTaxes | priceFormat }}</span>
          </div>
        </section>

        <div class="mt-4 mt-lg-5 pt-lg-5">
          <b-button block :to="{ name: 'trip-view', params: { trip_id: roundTrip.extendedProps.trip.id } }">
            <font-awesome-icon icon="info-circle" class="mr-50" />
            {{ $t('action.show') }}
          </b-button>
          <b-button block variant="outline-primary" @click="hide()">
            <font-awesome-icon icon="arrow-left" class="mr-50" />
            {{ $t('action.back') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import UIHelper from '@/helpers/ui'
import { fetchTripOrganizationInfosRequest } from '@/request/globalApi/requests/tripRequest'
import TripHelper from '@/helpers/trip'

export default defineComponent({
  name: 'SidebarTripResume',

  mixins: [UIHelper, TripHelper],

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    roundTrip: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { $store } = ctx.root
    const isLoading = ref(false)
    const tripStepEvent = ref({})

    const displayDeparture = ref(false)
    const displayArrival = ref(false)

    watch(() => props.value, value => {
      if ($store.state.auth.selectedOrganization.seller) {
        if (value) {
          isLoading.value = true
          displayDeparture.value = false
          displayArrival.value = false
          fetchTripOrganizationInfosRequest(props.roundTrip.extendedProps.trip.id)
            .then(({ data }) => {
              data.tripStepEvents.forEach(sellerTripStepEvent => {
                if (sellerTripStepEvent.tripStep.id === props.roundTrip.extendedProps.step.id) {
                  if (sellerTripStepEvent.className.includes('Departure')) {
                    displayDeparture.value = true
                  }

                  if (sellerTripStepEvent.className.includes('Arrival')) {
                    displayArrival.value = true
                  }

                  tripStepEvent.value = sellerTripStepEvent
                }
              })
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          isLoading.value = false
          tripStepEvent.value = {}
        }
      }
    })

    const getEventTimezone = (step, event) => {
      if (event.className.includes('Flight')) {
        return event.address.timeZone
      }

      if (event.className.includes('Transfer')) {
        if (event.className.includes('Departure')) {
          return step.departureDepositZone ? step.departureDepositZone.timeZone : event.address.timeZone
        }

        return step.arrivalDepositZone ? step.arrivalDepositZone.timeZone : event.address.timeZone
      }
    }

    return {
      isLoading,
      tripStepEvent,
      getEventTimezone,
      displayDeparture,
      displayArrival,
    }
  },
})
</script>
