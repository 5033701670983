<template>
  <section>
    <!--CALENDAR-->
    <b-overlay :show="tripsLoading">
      <b-card class="app-calendar overflow-hidden border">
        <div class="row no-gutters">
          <!-- Calendar -->
          <div class="col position-relative">
            <b-form-group :label="$t('dashboard.trip.calendar_date')" label-for="start-at-calendar">
              <b-form-datepicker
                id="start-at-calendar"
                v-model="dateCalendarSelected"
                :locale="$i18n.locale"
                :no-flip="true"
                :placeholder="$t('dashboard.trip.calendar_date')"
                class="mb-1"
              />
            </b-form-group>
            <!--CALENDAR-->
            <full-calendar
              ref="calendarSlot"
              :options="calendarOptions"
              :plugins="calendarOptions.plugins"
              class="full-calendar"
            />
          </div>
        </div>
      </b-card>
      <!-- SIDEBAR TRIP INFO -->
      <SidebarTripResume
        v-model="isTripResumeSidebarActive"
        :round-trip="roundTripSelected"
      />
    </b-overlay>
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import UIHelper from '@/helpers/ui'
import SidebarTripResume from '@/views/trip/sidebar/SidebarTripResume.vue'
import { fetchTripsCalendarRequest } from '@/request/globalApi/requests/tripRequest'
import TripHelper from '@/helpers/trip'

export default {
  name: 'TripCalendar',

  components: {
    FullCalendar,
    SidebarTripResume,
  },

  mixins: [UIHelper, TripHelper],
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tripsLoading: false,
      trips: [],
      displayedDates: {},
      isTripResumeSidebarActive: false,
      roundTripSelected: {},
      dateCalendarSelected: '',
      dateSlotSelected: '',
      timeSlotSelected: '',
      tripSelected: null,
      currentDate: null,
      calendarOptions: {
        timeZone: 'UTC',
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: this.$route.query.mode,
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, prev, next, title',
          end: 'timeGridWeek,listMonth,today',
        },
        contentHeight: 500,
        initialDate: this.$moment(this.$route.query.start).endOf('isoWeek').format('YYYY-MM-DD'),
        events: [],
        datesSet: event => {
          const getDay = day => (day ? this.$moment(day).format('YYYY-MM-DD') : null)
          if (getDay(event.start) === this.displayedDates.start && this.displayedDates.mode === event.view.type) return

          this.displayedDates = {
            start: getDay(event.start),
            end: getDay(event.end),
            mode: event.view.type,
          }
          this.$router.replace({ query: { ...this.$route.query, ...this.displayedDates, end: undefined } })
          this.fetchTripsCalendar({ start: getDay(event.start), end: getDay(event.end) })
        },
        selectable: true,
        eventClick: el => {
          this.roundTripSelected = el.event
          setTimeout(() => {
            this.isTripResumeSidebarActive = true
          }, 100)
        },
        textEscape: false,
        eventResizableFromStart: true,
        fixedWeekCount: false,
        weekNumberCalculation: 'ISO',
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, DD MMM',
        locale: this.$i18n.locale,
        firstDay: 1,
      },
      eventSkeleton: {
        id: null,
        title: '',
        start: null,
        end: null,
        allDay: false,
        color: '#DCC181',
        textColor: 'white',
        extendedProps: null,
      },
    }
  },
  watch: {
    dateCalendarSelected(val) {
      this.displayedDates.start = val
      const calendarApi = this.$refs.calendarSlot.getApi()
      calendarApi.gotoDate(val)
    },
  },
  methods: {
    // Create calendar event for each trip
    buildEvent() {
      this.calendarOptions.events = []
      const roundTrips = []
      this.trips.forEach(trip => {
        trip.tripSteps.forEach(step => {
          const event = JSON.parse(JSON.stringify(this.eventSkeleton))
          let departure = null
          let arrival = null
          if (step.className === 'TripFlight') {
            departure = step.flightDeparture
            arrival = step.flightArrival
            event.title = `${trip.organizationAircraft.type} - ${trip.organizationAircraft.callSign}`
            if (departure.airport && arrival.airport) {
              event.title = `✈ ${event.title} : ${departure.airport.icaoCode} > ${arrival.airport.icaoCode}`
            } else {
              event.title = `✈ ${event.title} : ${departure.address.city} > ${arrival.address.city}`
            }
            event.icon = '<i class="fa-solid fa-plane"></i>'
          } else {
            departure = step.transferDeparture
            arrival = step.transferArrival
            event.title = `🚁 ${this.tripTransferShortEventName(step.transferDeparture, step.departureDepositZone)} > ${this.tripTransferShortEventName(step.transferArrival, step.arrivalDepositZone)}`
            event.icon = '<i class="fa-solid fa-helicopter"></i>'
          }

          roundTrips.push(departure, step)
          event.id = step.id
          event.groupId = step.id
          // Start
          event.start = this.getDayTime(departure.eventDateTime)
          // End
          if (arrival.eventDateTime) {
            event.end = this.getDayTime(arrival.eventDateTime)
          } else {
            event.end = this.getDayTime(departure.eventDateTime)
          }

          event.extendedProps = {
            trip,
            step,
            departure,
            arrival,
          }

          if (event.start && event.end) this.calendarOptions.events.push(event)
        })
      })
    },
    fetchTripsCalendar(query) {
      this.tripsLoading = true
      const params = {
        ...this.params,
        ...query,
        getAllItems: true,
      }
      fetchTripsCalendarRequest(params)
        .then(response => {
          const { trips, totalItems } = response.data
          this.totalItems = totalItems
          this.trips = trips
          this.buildEvent()
        })
        .finally(() => {
          this.tripsLoading = false
        })
    },
    getDayTime(day) {
      return day ? this.$moment.utc(day).format('YYYY-MM-DD HH:mm:ss') : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.airport-info-wrapper {
  max-height: 950px;
  overflow: auto;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}
</style>
